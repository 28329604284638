// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: criteriaselector/dataschema/filterable_group.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Key } from "../key";
import { ValueData } from "../value_data";

export const protobufPackage = "tanagra.dataschema";

/** Data for an GroupItems entity group criteria with filterable attributes. */
export interface FilterableGroup {
  /**
   * The list of selections, each of which may be a single item or a set of
   * parameters.
   */
  selected: FilterableGroup_Selection[];
}

export interface FilterableGroup_SingleSelect {
  /**
   * The key of the selected value, which references group entity (e.g.
   * variant when filtering variantPerson).
   */
  key:
    | Key
    | undefined;
  /**
   * The visible name for the selection. This is stored to avoid extra lookups
   * when rendering.
   */
  name: string;
}

export interface FilterableGroup_SelectAll {
  /** The string query of the selection. */
  query: string;
  /** Optional values that comprise the selection. */
  valueData: ValueData[];
  /** Single items excluded from this selection. */
  exclusions: FilterableGroup_SingleSelect[];
}

export interface FilterableGroup_Selection {
  /**
   * A unique id to refer to selections since SelectAlls don't naturally have
   * one.
   */
  id: string;
  single?: FilterableGroup_SingleSelect | undefined;
  all?: FilterableGroup_SelectAll | undefined;
}

function createBaseFilterableGroup(): FilterableGroup {
  return { selected: [] };
}

export const FilterableGroup = {
  encode(message: FilterableGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selected) {
      FilterableGroup_Selection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selected.push(FilterableGroup_Selection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup {
    return {
      selected: globalThis.Array.isArray(object?.selected)
        ? object.selected.map((e: any) => FilterableGroup_Selection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FilterableGroup): unknown {
    const obj: any = {};
    if (message.selected?.length) {
      obj.selected = message.selected.map((e) => FilterableGroup_Selection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup>, I>>(base?: I): FilterableGroup {
    return FilterableGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup>, I>>(object: I): FilterableGroup {
    const message = createBaseFilterableGroup();
    message.selected = object.selected?.map((e) => FilterableGroup_Selection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFilterableGroup_SingleSelect(): FilterableGroup_SingleSelect {
  return { key: undefined, name: "" };
}

export const FilterableGroup_SingleSelect = {
  encode(message: FilterableGroup_SingleSelect, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup_SingleSelect {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup_SingleSelect();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup_SingleSelect {
    return {
      key: isSet(object.key) ? Key.fromJSON(object.key) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: FilterableGroup_SingleSelect): unknown {
    const obj: any = {};
    if (message.key !== undefined) {
      obj.key = Key.toJSON(message.key);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup_SingleSelect>, I>>(base?: I): FilterableGroup_SingleSelect {
    return FilterableGroup_SingleSelect.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup_SingleSelect>, I>>(object: I): FilterableGroup_SingleSelect {
    const message = createBaseFilterableGroup_SingleSelect();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseFilterableGroup_SelectAll(): FilterableGroup_SelectAll {
  return { query: "", valueData: [], exclusions: [] };
}

export const FilterableGroup_SelectAll = {
  encode(message: FilterableGroup_SelectAll, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.query !== "") {
      writer.uint32(10).string(message.query);
    }
    for (const v of message.valueData) {
      ValueData.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.exclusions) {
      FilterableGroup_SingleSelect.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup_SelectAll {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup_SelectAll();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.valueData.push(ValueData.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.exclusions.push(FilterableGroup_SingleSelect.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup_SelectAll {
    return {
      query: isSet(object.query) ? globalThis.String(object.query) : "",
      valueData: globalThis.Array.isArray(object?.valueData)
        ? object.valueData.map((e: any) => ValueData.fromJSON(e))
        : [],
      exclusions: globalThis.Array.isArray(object?.exclusions)
        ? object.exclusions.map((e: any) => FilterableGroup_SingleSelect.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FilterableGroup_SelectAll): unknown {
    const obj: any = {};
    if (message.query !== "") {
      obj.query = message.query;
    }
    if (message.valueData?.length) {
      obj.valueData = message.valueData.map((e) => ValueData.toJSON(e));
    }
    if (message.exclusions?.length) {
      obj.exclusions = message.exclusions.map((e) => FilterableGroup_SingleSelect.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup_SelectAll>, I>>(base?: I): FilterableGroup_SelectAll {
    return FilterableGroup_SelectAll.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup_SelectAll>, I>>(object: I): FilterableGroup_SelectAll {
    const message = createBaseFilterableGroup_SelectAll();
    message.query = object.query ?? "";
    message.valueData = object.valueData?.map((e) => ValueData.fromPartial(e)) || [];
    message.exclusions = object.exclusions?.map((e) => FilterableGroup_SingleSelect.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFilterableGroup_Selection(): FilterableGroup_Selection {
  return { id: "", single: undefined, all: undefined };
}

export const FilterableGroup_Selection = {
  encode(message: FilterableGroup_Selection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.single !== undefined) {
      FilterableGroup_SingleSelect.encode(message.single, writer.uint32(18).fork()).ldelim();
    }
    if (message.all !== undefined) {
      FilterableGroup_SelectAll.encode(message.all, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup_Selection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup_Selection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.single = FilterableGroup_SingleSelect.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.all = FilterableGroup_SelectAll.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup_Selection {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      single: isSet(object.single) ? FilterableGroup_SingleSelect.fromJSON(object.single) : undefined,
      all: isSet(object.all) ? FilterableGroup_SelectAll.fromJSON(object.all) : undefined,
    };
  },

  toJSON(message: FilterableGroup_Selection): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.single !== undefined) {
      obj.single = FilterableGroup_SingleSelect.toJSON(message.single);
    }
    if (message.all !== undefined) {
      obj.all = FilterableGroup_SelectAll.toJSON(message.all);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup_Selection>, I>>(base?: I): FilterableGroup_Selection {
    return FilterableGroup_Selection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup_Selection>, I>>(object: I): FilterableGroup_Selection {
    const message = createBaseFilterableGroup_Selection();
    message.id = object.id ?? "";
    message.single = (object.single !== undefined && object.single !== null)
      ? FilterableGroup_SingleSelect.fromPartial(object.single)
      : undefined;
    message.all = (object.all !== undefined && object.all !== null)
      ? FilterableGroup_SelectAll.fromPartial(object.all)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
