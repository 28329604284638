// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: criteriaselector/configschema/filterable_group.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Column } from "../../column";
import { SortOrder } from "../../sort_order";
import { ValueConfig } from "../value_config";

export const protobufPackage = "tanagra.configschema";

/**
 * Config for an GroupItems entity group criteria with filterable attributes and
 * the ability to select all items that match a set of attributes.
 */
export interface FilterableGroup {
  /** Columns displayed in the list view. */
  columns: Column[];
  /** The GroupItems entity group to be filtered. */
  entityGroup: string;
  /** Attributes that can be part of a select all. */
  valueConfigs: ValueConfig[];
  /** The sort order to use in the list view. */
  sortOrder:
    | SortOrder
    | undefined;
  /**
   * Number of values to display on each page in the list view. Otherwise, a
   * default value is applied.
   */
  pageSize?: number | undefined;
  searchConfigs: FilterableGroup_SearchConfig[];
}

/**
 * Each SearchConfig corresponds to a valid search query format and is a regex
 * which are checked in order with the first matching one being used. Each
 * capture group is mapped to a Parameter which will be checked against a
 * specified attribute using a specified operator. If no capture groups are
 * specified, then the entire match is mapped to the first parameter. If there
 * are no configs, a generic text search will be performed across the search
 * fields specified by the entity.
 */
export interface FilterableGroup_SearchConfig {
  /** The list of names and corresponding examples are shown in a tooltip. */
  name: string;
  example: string;
  /** The regular expression to match against. */
  regex: string;
  /**
   * The tooltips are sorted according to displayOrder, which defaults to 0
   * when unspecified, and falls back on the order specified in the config.
   * This allows the visible order to differ from the matching order.
   */
  displayOrder: number;
  parameters: FilterableGroup_SearchConfig_Parameter[];
}

/**
 * Each parameter corresponds to a capture group in the regex, or the entire
 * match if none are specified. At least one parameter must be specified.
 * The order of arguments is <attribute> <operator> <query>.
 */
export interface FilterableGroup_SearchConfig_Parameter {
  /** The attribute to compare against. */
  attribute: string;
  operator: FilterableGroup_SearchConfig_Parameter_Operator;
  case: FilterableGroup_SearchConfig_Parameter_Case;
}

/** The operator to compare with. */
export enum FilterableGroup_SearchConfig_Parameter_Operator {
  OPERATOR_UNKNOWN = 0,
  OPERATOR_EQUALS = 1,
  OPERATOR_GREATER_THAN = 2,
  OPERATOR_GREATER_THAN_OR_EQUAL = 3,
  OPERATOR_LESS_THAN = 4,
  OPERATOR_LESS_THAN_OR_EQUAL = 5,
  UNRECOGNIZED = -1,
}

export function filterableGroup_SearchConfig_Parameter_OperatorFromJSON(
  object: any,
): FilterableGroup_SearchConfig_Parameter_Operator {
  switch (object) {
    case 0:
    case "OPERATOR_UNKNOWN":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_UNKNOWN;
    case 1:
    case "OPERATOR_EQUALS":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_EQUALS;
    case 2:
    case "OPERATOR_GREATER_THAN":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_GREATER_THAN;
    case 3:
    case "OPERATOR_GREATER_THAN_OR_EQUAL":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_GREATER_THAN_OR_EQUAL;
    case 4:
    case "OPERATOR_LESS_THAN":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_LESS_THAN;
    case 5:
    case "OPERATOR_LESS_THAN_OR_EQUAL":
      return FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_LESS_THAN_OR_EQUAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilterableGroup_SearchConfig_Parameter_Operator.UNRECOGNIZED;
  }
}

export function filterableGroup_SearchConfig_Parameter_OperatorToJSON(
  object: FilterableGroup_SearchConfig_Parameter_Operator,
): string {
  switch (object) {
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_UNKNOWN:
      return "OPERATOR_UNKNOWN";
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_EQUALS:
      return "OPERATOR_EQUALS";
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_GREATER_THAN:
      return "OPERATOR_GREATER_THAN";
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_GREATER_THAN_OR_EQUAL:
      return "OPERATOR_GREATER_THAN_OR_EQUAL";
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_LESS_THAN:
      return "OPERATOR_LESS_THAN";
    case FilterableGroup_SearchConfig_Parameter_Operator.OPERATOR_LESS_THAN_OR_EQUAL:
      return "OPERATOR_LESS_THAN_OR_EQUAL";
    case FilterableGroup_SearchConfig_Parameter_Operator.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** If specified, the matched string is converted to lower or upper case. */
export enum FilterableGroup_SearchConfig_Parameter_Case {
  CASE_NONE = 0,
  CASE_LOWER = 1,
  CASE_UPPER = 2,
  UNRECOGNIZED = -1,
}

export function filterableGroup_SearchConfig_Parameter_CaseFromJSON(
  object: any,
): FilterableGroup_SearchConfig_Parameter_Case {
  switch (object) {
    case 0:
    case "CASE_NONE":
      return FilterableGroup_SearchConfig_Parameter_Case.CASE_NONE;
    case 1:
    case "CASE_LOWER":
      return FilterableGroup_SearchConfig_Parameter_Case.CASE_LOWER;
    case 2:
    case "CASE_UPPER":
      return FilterableGroup_SearchConfig_Parameter_Case.CASE_UPPER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FilterableGroup_SearchConfig_Parameter_Case.UNRECOGNIZED;
  }
}

export function filterableGroup_SearchConfig_Parameter_CaseToJSON(
  object: FilterableGroup_SearchConfig_Parameter_Case,
): string {
  switch (object) {
    case FilterableGroup_SearchConfig_Parameter_Case.CASE_NONE:
      return "CASE_NONE";
    case FilterableGroup_SearchConfig_Parameter_Case.CASE_LOWER:
      return "CASE_LOWER";
    case FilterableGroup_SearchConfig_Parameter_Case.CASE_UPPER:
      return "CASE_UPPER";
    case FilterableGroup_SearchConfig_Parameter_Case.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseFilterableGroup(): FilterableGroup {
  return {
    columns: [],
    entityGroup: "",
    valueConfigs: [],
    sortOrder: undefined,
    pageSize: undefined,
    searchConfigs: [],
  };
}

export const FilterableGroup = {
  encode(message: FilterableGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.columns) {
      Column.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.entityGroup !== "") {
      writer.uint32(18).string(message.entityGroup);
    }
    for (const v of message.valueConfigs) {
      ValueConfig.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.sortOrder !== undefined) {
      SortOrder.encode(message.sortOrder, writer.uint32(34).fork()).ldelim();
    }
    if (message.pageSize !== undefined) {
      writer.uint32(40).int32(message.pageSize);
    }
    for (const v of message.searchConfigs) {
      FilterableGroup_SearchConfig.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.columns.push(Column.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.entityGroup = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.valueConfigs.push(ValueConfig.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sortOrder = SortOrder.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.searchConfigs.push(FilterableGroup_SearchConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup {
    return {
      columns: globalThis.Array.isArray(object?.columns) ? object.columns.map((e: any) => Column.fromJSON(e)) : [],
      entityGroup: isSet(object.entityGroup) ? globalThis.String(object.entityGroup) : "",
      valueConfigs: globalThis.Array.isArray(object?.valueConfigs)
        ? object.valueConfigs.map((e: any) => ValueConfig.fromJSON(e))
        : [],
      sortOrder: isSet(object.sortOrder) ? SortOrder.fromJSON(object.sortOrder) : undefined,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : undefined,
      searchConfigs: globalThis.Array.isArray(object?.searchConfigs)
        ? object.searchConfigs.map((e: any) => FilterableGroup_SearchConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FilterableGroup): unknown {
    const obj: any = {};
    if (message.columns?.length) {
      obj.columns = message.columns.map((e) => Column.toJSON(e));
    }
    if (message.entityGroup !== "") {
      obj.entityGroup = message.entityGroup;
    }
    if (message.valueConfigs?.length) {
      obj.valueConfigs = message.valueConfigs.map((e) => ValueConfig.toJSON(e));
    }
    if (message.sortOrder !== undefined) {
      obj.sortOrder = SortOrder.toJSON(message.sortOrder);
    }
    if (message.pageSize !== undefined) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.searchConfigs?.length) {
      obj.searchConfigs = message.searchConfigs.map((e) => FilterableGroup_SearchConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup>, I>>(base?: I): FilterableGroup {
    return FilterableGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup>, I>>(object: I): FilterableGroup {
    const message = createBaseFilterableGroup();
    message.columns = object.columns?.map((e) => Column.fromPartial(e)) || [];
    message.entityGroup = object.entityGroup ?? "";
    message.valueConfigs = object.valueConfigs?.map((e) => ValueConfig.fromPartial(e)) || [];
    message.sortOrder = (object.sortOrder !== undefined && object.sortOrder !== null)
      ? SortOrder.fromPartial(object.sortOrder)
      : undefined;
    message.pageSize = object.pageSize ?? undefined;
    message.searchConfigs = object.searchConfigs?.map((e) => FilterableGroup_SearchConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFilterableGroup_SearchConfig(): FilterableGroup_SearchConfig {
  return { name: "", example: "", regex: "", displayOrder: 0, parameters: [] };
}

export const FilterableGroup_SearchConfig = {
  encode(message: FilterableGroup_SearchConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.example !== "") {
      writer.uint32(18).string(message.example);
    }
    if (message.regex !== "") {
      writer.uint32(26).string(message.regex);
    }
    if (message.displayOrder !== 0) {
      writer.uint32(32).int64(message.displayOrder);
    }
    for (const v of message.parameters) {
      FilterableGroup_SearchConfig_Parameter.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup_SearchConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup_SearchConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.example = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.regex = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.displayOrder = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.parameters.push(FilterableGroup_SearchConfig_Parameter.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup_SearchConfig {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      example: isSet(object.example) ? globalThis.String(object.example) : "",
      regex: isSet(object.regex) ? globalThis.String(object.regex) : "",
      displayOrder: isSet(object.displayOrder) ? globalThis.Number(object.displayOrder) : 0,
      parameters: globalThis.Array.isArray(object?.parameters)
        ? object.parameters.map((e: any) => FilterableGroup_SearchConfig_Parameter.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FilterableGroup_SearchConfig): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.example !== "") {
      obj.example = message.example;
    }
    if (message.regex !== "") {
      obj.regex = message.regex;
    }
    if (message.displayOrder !== 0) {
      obj.displayOrder = Math.round(message.displayOrder);
    }
    if (message.parameters?.length) {
      obj.parameters = message.parameters.map((e) => FilterableGroup_SearchConfig_Parameter.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup_SearchConfig>, I>>(base?: I): FilterableGroup_SearchConfig {
    return FilterableGroup_SearchConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup_SearchConfig>, I>>(object: I): FilterableGroup_SearchConfig {
    const message = createBaseFilterableGroup_SearchConfig();
    message.name = object.name ?? "";
    message.example = object.example ?? "";
    message.regex = object.regex ?? "";
    message.displayOrder = object.displayOrder ?? 0;
    message.parameters = object.parameters?.map((e) => FilterableGroup_SearchConfig_Parameter.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFilterableGroup_SearchConfig_Parameter(): FilterableGroup_SearchConfig_Parameter {
  return { attribute: "", operator: 0, case: 0 };
}

export const FilterableGroup_SearchConfig_Parameter = {
  encode(message: FilterableGroup_SearchConfig_Parameter, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.attribute !== "") {
      writer.uint32(10).string(message.attribute);
    }
    if (message.operator !== 0) {
      writer.uint32(16).int32(message.operator);
    }
    if (message.case !== 0) {
      writer.uint32(24).int32(message.case);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FilterableGroup_SearchConfig_Parameter {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterableGroup_SearchConfig_Parameter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.attribute = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.operator = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.case = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FilterableGroup_SearchConfig_Parameter {
    return {
      attribute: isSet(object.attribute) ? globalThis.String(object.attribute) : "",
      operator: isSet(object.operator) ? filterableGroup_SearchConfig_Parameter_OperatorFromJSON(object.operator) : 0,
      case: isSet(object.case) ? filterableGroup_SearchConfig_Parameter_CaseFromJSON(object.case) : 0,
    };
  },

  toJSON(message: FilterableGroup_SearchConfig_Parameter): unknown {
    const obj: any = {};
    if (message.attribute !== "") {
      obj.attribute = message.attribute;
    }
    if (message.operator !== 0) {
      obj.operator = filterableGroup_SearchConfig_Parameter_OperatorToJSON(message.operator);
    }
    if (message.case !== 0) {
      obj.case = filterableGroup_SearchConfig_Parameter_CaseToJSON(message.case);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FilterableGroup_SearchConfig_Parameter>, I>>(
    base?: I,
  ): FilterableGroup_SearchConfig_Parameter {
    return FilterableGroup_SearchConfig_Parameter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterableGroup_SearchConfig_Parameter>, I>>(
    object: I,
  ): FilterableGroup_SearchConfig_Parameter {
    const message = createBaseFilterableGroup_SearchConfig_Parameter();
    message.attribute = object.attribute ?? "";
    message.operator = object.operator ?? 0;
    message.case = object.case ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
