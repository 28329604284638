// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: column.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tanagra";

/** Defines a column in the UI. */
export interface Column {
  /**
   * A unique key for the column. By default, used to look up attributes in
   * the displayed data.
   */
  key: string;
  /**
   * Passed directly to the style of the column. "100%" can be used to take
   * up space remaining after laying out fixed columns.
   */
  widthString?:
    | string
    | undefined;
  /** Units used by the UI library to standardize dimensions. */
  widthDouble?:
    | number
    | undefined;
  /** The visible title of the column. */
  title: string;
  /** Whether the column supports sorting. */
  sortable: boolean;
  /** Whether the column supports filtering. */
  filterable: boolean;
}

function createBaseColumn(): Column {
  return { key: "", widthString: undefined, widthDouble: undefined, title: "", sortable: false, filterable: false };
}

export const Column = {
  encode(message: Column, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.widthString !== undefined) {
      writer.uint32(18).string(message.widthString);
    }
    if (message.widthDouble !== undefined) {
      writer.uint32(25).double(message.widthDouble);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.sortable !== false) {
      writer.uint32(40).bool(message.sortable);
    }
    if (message.filterable !== false) {
      writer.uint32(48).bool(message.filterable);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Column {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseColumn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.widthString = reader.string();
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.widthDouble = reader.double();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sortable = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.filterable = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Column {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      widthString: isSet(object.widthString) ? globalThis.String(object.widthString) : undefined,
      widthDouble: isSet(object.widthDouble) ? globalThis.Number(object.widthDouble) : undefined,
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      sortable: isSet(object.sortable) ? globalThis.Boolean(object.sortable) : false,
      filterable: isSet(object.filterable) ? globalThis.Boolean(object.filterable) : false,
    };
  },

  toJSON(message: Column): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.widthString !== undefined) {
      obj.widthString = message.widthString;
    }
    if (message.widthDouble !== undefined) {
      obj.widthDouble = message.widthDouble;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.sortable !== false) {
      obj.sortable = message.sortable;
    }
    if (message.filterable !== false) {
      obj.filterable = message.filterable;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Column>, I>>(base?: I): Column {
    return Column.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Column>, I>>(object: I): Column {
    const message = createBaseColumn();
    message.key = object.key ?? "";
    message.widthString = object.widthString ?? undefined;
    message.widthDouble = object.widthDouble ?? undefined;
    message.title = object.title ?? "";
    message.sortable = object.sortable ?? false;
    message.filterable = object.filterable ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
