// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v5.29.1
// source: criteriaselector/dataschema/survey.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Key } from "../key";
import { ValueData } from "../value_data";

export const protobufPackage = "tanagra.dataschema";

/** Data for an entity group criteria is a list of selected values. */
export interface Survey {
  selected: Survey_Selection[];
  /**
   * Data for an additional categorical or numeric value associated with the
   * selection (e.g. a numeric answer).
   * Temporarily unused in favor of per-selection value_data but will
   * potentially be used in future to support criteria wide values (e.g. survey
   * version or date).
   */
  valueData: ValueData | undefined;
}

export interface Survey_Selection {
  /**
   * The key of the selected value, which references a related entity (e.g.
   * surveyBasics when filtering surveyOccurrence).
   */
  key:
    | Key
    | undefined;
  /**
   * The visible name for the selection. This is stored to avoid extra lookups
   * when rendering.
   */
  name: string;
  /**
   * The entity group is stored to differentiate between them when multiple
   * are configured within a single criteria.
   */
  entityGroup: string;
  /** If the selected item is an answer, the key of the question it belongs to. */
  questionKey:
    | Key
    | undefined;
  /**
   * If the selected item is an answer, the visible name of the question it
   * belongs to.
   */
  questionName: string;
  /**
   * Data for additional categorical or numeric values associated with the
   * selection (e.g. a numeric answer).
   */
  valueData: ValueData | undefined;
}

function createBaseSurvey(): Survey {
  return { selected: [], valueData: undefined };
}

export const Survey = {
  encode(message: Survey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selected) {
      Survey_Selection.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.valueData !== undefined) {
      ValueData.encode(message.valueData, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Survey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSurvey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selected.push(Survey_Selection.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.valueData = ValueData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Survey {
    return {
      selected: globalThis.Array.isArray(object?.selected)
        ? object.selected.map((e: any) => Survey_Selection.fromJSON(e))
        : [],
      valueData: isSet(object.valueData) ? ValueData.fromJSON(object.valueData) : undefined,
    };
  },

  toJSON(message: Survey): unknown {
    const obj: any = {};
    if (message.selected?.length) {
      obj.selected = message.selected.map((e) => Survey_Selection.toJSON(e));
    }
    if (message.valueData !== undefined) {
      obj.valueData = ValueData.toJSON(message.valueData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Survey>, I>>(base?: I): Survey {
    return Survey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Survey>, I>>(object: I): Survey {
    const message = createBaseSurvey();
    message.selected = object.selected?.map((e) => Survey_Selection.fromPartial(e)) || [];
    message.valueData = (object.valueData !== undefined && object.valueData !== null)
      ? ValueData.fromPartial(object.valueData)
      : undefined;
    return message;
  },
};

function createBaseSurvey_Selection(): Survey_Selection {
  return { key: undefined, name: "", entityGroup: "", questionKey: undefined, questionName: "", valueData: undefined };
}

export const Survey_Selection = {
  encode(message: Survey_Selection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.entityGroup !== "") {
      writer.uint32(26).string(message.entityGroup);
    }
    if (message.questionKey !== undefined) {
      Key.encode(message.questionKey, writer.uint32(34).fork()).ldelim();
    }
    if (message.questionName !== "") {
      writer.uint32(42).string(message.questionName);
    }
    if (message.valueData !== undefined) {
      ValueData.encode(message.valueData, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Survey_Selection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSurvey_Selection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.entityGroup = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.questionKey = Key.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.questionName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.valueData = ValueData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Survey_Selection {
    return {
      key: isSet(object.key) ? Key.fromJSON(object.key) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      entityGroup: isSet(object.entityGroup) ? globalThis.String(object.entityGroup) : "",
      questionKey: isSet(object.questionKey) ? Key.fromJSON(object.questionKey) : undefined,
      questionName: isSet(object.questionName) ? globalThis.String(object.questionName) : "",
      valueData: isSet(object.valueData) ? ValueData.fromJSON(object.valueData) : undefined,
    };
  },

  toJSON(message: Survey_Selection): unknown {
    const obj: any = {};
    if (message.key !== undefined) {
      obj.key = Key.toJSON(message.key);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.entityGroup !== "") {
      obj.entityGroup = message.entityGroup;
    }
    if (message.questionKey !== undefined) {
      obj.questionKey = Key.toJSON(message.questionKey);
    }
    if (message.questionName !== "") {
      obj.questionName = message.questionName;
    }
    if (message.valueData !== undefined) {
      obj.valueData = ValueData.toJSON(message.valueData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Survey_Selection>, I>>(base?: I): Survey_Selection {
    return Survey_Selection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Survey_Selection>, I>>(object: I): Survey_Selection {
    const message = createBaseSurvey_Selection();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.name = object.name ?? "";
    message.entityGroup = object.entityGroup ?? "";
    message.questionKey = (object.questionKey !== undefined && object.questionKey !== null)
      ? Key.fromPartial(object.questionKey)
      : undefined;
    message.questionName = object.questionName ?? "";
    message.valueData = (object.valueData !== undefined && object.valueData !== null)
      ? ValueData.fromPartial(object.valueData)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
